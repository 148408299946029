import React from 'react';
import { Layout } from '../components/Layout';

const OrderPlacedFail = () => {
  return (
    <Layout title="Quote Request Incomplete" description="" keywords="">
      <main>
        <h1>Survey Quote Request Failed</h1>
        <p>
          Oh no! Something has gone wrong when trying to submit your survey request. Please try
          again or give us a call.
        </p>
      </main>
    </Layout>
  );
};

export default OrderPlacedFail;
